import React from "react";
import { BrowserRouter as Router, } from "react-router-dom";
import { AppContainer } from "app-libs-ui/components";
import { store } from "app-libs-ui/redux";
import { services } from "app-libs-ui";

import './index.css';

import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux'
import { Deployment } from "app-models-specification-ts/user";
import { DeploymentTypeSpec } from "./typeSpecs/DeploymentTypeSpec.jsx";

const appContainer = document.getElementById('app');
const root = createRoot(appContainer!);

const typeSpecs = {
    [Deployment.name]: DeploymentTypeSpec,
}

export const renderApp = () => root.render(
    <Router>
        <Provider store={store}>
            <AppContainer typeSpecs={typeSpecs} />
        </Provider>
    </Router>
);

services.UserService.initKeycloak(renderApp);